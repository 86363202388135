<template>
  <div>
    <NavigationBar titel="Kalender" :actions="actions"></NavigationBar>
    <section>
      <TippCard
        hintID="Terminplanung_Kalender_1_V1"
        text="Im Kalender werden Veranstaltungen und eingetragene Termine angezeigt. Verschiedene Ansichten lassen vorausschauend planen."
      >
      </TippCard>
    </section>
    <section>
      <template>
        <v-container fluid>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <v-card height="800px" class="overflow-hidden">
                <v-toolbar height="64" outlined flat>
                  <v-btn outlined small @click="prev" class="ml-2 mr-2">
                    <v-icon dense>mdi-chevron-left</v-icon>
                  </v-btn>
                  <v-btn outlined small @click="next" class="mr-4">
                    <v-icon dense>mdi-chevron-right</v-icon>
                  </v-btn>
                  <v-toolbar-title>{{ calendarTitle }}</v-toolbar-title>
                  <div class="flex-grow-1"></div>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-if="
                          $store.state.organization.activeOrganization.config
                            .scheduling.showAllEventsInList ||
                          checkIfSupport ||
                          checkIfItService
                        "
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        class="mr-4"
                      >
                        <span>
                          <v-icon left small>mdi-calendar-filter</v-icon>
                          {{ eventsFilter }}
                        </span>
                        <v-icon right>mdi-menu-down</v-icon>
                      </v-btn>
                    </template>
                    <v-list nav>
                      <v-list-item-group v-model="eventsFilter" mandatory>
                        <v-list-item link dense value="Alle">
                          <v-list-item-icon>
                            <v-icon dense>mdi-all-inclusive</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title
                            >Alle Veranstaltungen anzeigen</v-list-item-title
                          >
                        </v-list-item>
                        <v-list-item link dense value="Eigene">
                          <v-list-item-icon>
                            <v-icon dense>mdi-filter</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title
                            >Nur eigene Veranstaltungen</v-list-item-title
                          >
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-menu>
                  <v-btn outlined class="mr-4" @click="setToday"> Heute </v-btn>
                  <v-menu bottom right>
                    <template v-slot:activator="{ on }">
                      <v-btn outlined v-on="on">
                        <span>{{ typeToLabel[type] }}</span>
                        <v-icon right>mdi-menu-down</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="type = 'day'">
                        <v-list-item-title>Tag</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="type = 'week'">
                        <v-list-item-title>Woche</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="type = 'month'">
                        <v-list-item-title>Monat</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="type = '4day'">
                        <v-list-item-title>4 Tage</v-list-item-title>
                      </v-list-item>
                      <!-- <v-list-item @click="type = 'category'">
                        <v-list-item-title>Kategorie</v-list-item-title>
                      </v-list-item> -->
                    </v-list>
                  </v-menu>
                </v-toolbar>
                <v-calendar
                  ref="calendar"
                  v-model="value"
                  color="primary"
                  :events="events"
                  locale="de"
                  show-week
                  show-month-on-first
                  :weekdays="[1, 2, 3, 4, 5, 6, 0]"
                  :short-weekdays="calShortWeekdays"
                  :short-months="calShortMonths"
                  event-name="title"
                  :event-margin-bottom="3"
                  :now="today"
                  :type="type"
                  event-category="type.title"
                  :event-color="getEventColor"
                  @click:event="showEvent"
                  @click:more="viewDay"
                  @click:date="viewDay"
                  @change="updateRange"
                >
                  <template v-slot:day-body="{ date, week }">
                    <div
                      class="v-current-time"
                      :class="{ first: date === week[0].date }"
                      :style="{ top: nowY }"
                    ></div>
                  </template>
                </v-calendar>
                <v-menu
                  v-model="selectedOpen"
                  :close-on-content-click="false"
                  :activator="selectedElement"
                  max-width="650px"
                  offset-x
                >
                  <event-details-card
                    :event="selectedEvent"
                    showCloseButton
                    showDetailsButton
                    minWidth="440px"
                    maxWidth="500px"
                    @closeEvent="selectedOpen = false"
                  ></event-details-card>
                </v-menu>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <section v-if="checkIfSupport">
      <support-tools :sources="code"></support-tools>
    </section>
  </div>
</template>

<script>
import NavigationBar from "@/components/_systemwide/NavigationBar";
import SupportTools from "@/components/_system/helpers/SupportTools.vue";
import EventDetailsCard from "@/components/scheduling/EventDetailsCard.vue";
import TippCard from "@/components/_systemwide/Tipp_Card.vue";
import { SCHEDULING } from "@/store/modules.js";
import { GET_EVENTS } from "@/store/action-types.js";
import { SCHEDULING_CALENDAR_FEEDS_read } from "@/data/permission-types.js";

export default {
  name: "scheduling-calendar",
  components: {
    NavigationBar,
    TippCard,
    SupportTools,
    EventDetailsCard,
  },
  data() {
    return {
      actions: [
        {
          title: "Kalender einbinden",
          permission: `${SCHEDULING_CALENDAR_FEEDS_read}`,
          icon: "mdi-rss",
          actionStyle: "textBtn",
          function: () => {
            this.$router.push({ name: "scheduling-calendar-feeds" });
          },
        },
      ],
      eventsFilter: "Eigene",
      today: new Date().toISOString().substr(0, 10),
      focus: new Date().toISOString().substr(0, 10),
      type: "month",
      value: "",
      ready: false,
      typeToLabel: {
        month: "Monat",
        week: "Woche",
        day: "Tag",
        "4day": "4 Tage",
        // "category": "Kategorien"
      },
      title: null,
      start: null,
      end: null,
      color: "#1976D2", // default event color
      selectedEvent: null,
      selectedElement: null,
      selectedOpen: false,
      calShortMonths: false,
      calShortWeekdays: false,
    };
  },
  computed: {
    code() {
      return [
        { title: "events", data: this.events },
        { title: "selectedEvent", data: this.selectedEvent },
      ];
    },
    events() {
      return this.$store.state.scheduling.eventsISO;
    },
    checkIfSupport() {
      return this.$store.getters["organization/checkIfSupport"];
    },
    cal() {
      return this.ready ? this.$refs.calendar : null;
    },
    nowY() {
      return this.cal ? this.cal.timeToY(this.cal.times.now) + "px" : "-10px";
    },
    calendarTitle() {
      const { start, end } = this;
      if (!start || !end) {
        return "";
      }
      const startMonth = this.monthFormatter(start);
      const endMonth = this.monthFormatter(end);
      // const suffixMonth = startMonth === endMonth ? '' : endMonth
      const startYear = start.year;
      const endYear = end.year;
      // const suffixYear = startYear === endYear ? '' : endYear
      const startDay = start.day; //+ this.nth(start.day)
      const endDay = end.day; //+ this.nth(end.day)
      switch (this.type) {
        case "month":
          return `${startMonth} ${startYear}`;
        case "week":
          return `${startDay}. ${startMonth} ${startYear} bis ${endDay}. ${endMonth} ${endYear}`;
        case "4day":
          return `${startDay}. ${startMonth} ${startYear} bis ${endDay}. ${endMonth} ${endYear}`;
        case "day":
          return `${startDay}. ${startMonth} ${startYear}`;
      }
      return "";
    },
    monthFormatter() {
      return this.$refs.calendar.getFormatter({
        timeZone: "UTC",
        month: "long",
      });
    },
  },
  watch: {
    "$store.state.organization.activeOrganization.config.scheduling.showAllEventsInList":
      {
        immediate: true, // Execute immediately on component creation
        handler(showAllEventsInListNew) {
          // Check if showAllEventsInListNew is populated and then execute your code
          if (showAllEventsInListNew !== null && showAllEventsInListNew) {
            // this.helpers.dataIsLoading = true;

            this.$store
              .dispatch(`${SCHEDULING}${GET_EVENTS}`, {
                organizationId: this.$route.params.organizationId,
                withStatus: true,
                query: {
                  onlyOwn: false,
                },
                // withStatus: this.$store.getters["organization/checkPermissionByID"](
                //   `${SCHEDULING_EVENTS_RESPONSES_OWN_update}`
                // )
                //   ? true
                //   : false,
              })
              .then(
                () => {
                  // this.helpers.dataIsLoading = false;
                },
                (error) => {
                  console.error(error);
                }
              );
          } else {
            // this.helpers.dataIsLoading = false;
            (this.eventsFilter = "Eigene"),
              this.$store
                .dispatch(`${SCHEDULING}${GET_EVENTS}`, {
                  organizationId: this.$route.params.organizationId,
                  withStatus: true,
                  query: {
                    onlyOwn: false,
                  },
                })
                .then(
                  () => {
                    // this.helpers.dataIsLoading = false;
                  },
                  (error) => {
                    console.error(error);
                  }
                );
          }
        },
      },
  },
  mounted() {
    this.ready = true;
    this.scrollToTime();
    this.updateTime();
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (window.outerWidth < 600) {
        this.calShortMonths = true;
        this.calShortWeekdays = true;
      }
    },

    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getCurrentTime() {
      return this.cal
        ? this.cal.times.now.hour * 60 + this.cal.times.now.minute
        : 0;
    },
    scrollToTime() {
      const time = this.getCurrentTime();
      const first = Math.max(0, time - (time % 30) - 30);

      this.cal.scrollToTime(first);
    },
    updateTime() {
      setInterval(() => this.cal.updateTimes(), 60 * 1000);
    },
    getEventColor(event) {
      return event.type.color;
    },
    setToday() {
      this.focus = this.today;
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => (this.selectedOpen = true), 10);
      };
      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }
      nativeEvent.stopPropagation();
    },
    updateRange({ start, end }) {
      this.start = start;
      this.end = end;
    },
    nth(d) {
      return d;
      // return d> 3 && d < 21
      //   ? 'th'
      //   : ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'][d % 10]
    },
  },
};
</script>

<style lang="scss">
.v-current-time {
  height: 2px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;

  &.first::before {
    content: "";
    position: absolute;
    background-color: #ea4335;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
  }
}
</style>
